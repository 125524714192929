import React from 'react';
import 'whatwg-fetch'
import './index.css'
import config from 'react-global-configuration';
import {Header} from "./header"
import infinitimg from "./img/svg/infinit.svg"
import {RightItem} from './right_item'


export class BlogList extends React.Component {

  constructor({ match }) {
    super();
    this.state = {

    };
  }

  componentDidMount() {


  }



  render() {

    const html_list = config.get("sites").map((item) =>{
      return (
        <RightItem url={`/photo/${item.url}`} item={item} category={item.category} />
      )
    })



    return (
      <div>


        <Header category="" image = "title"/>
        
        <div className="infnit"><div className="img"><img src={infinitimg}  alt="infinit" /></div></div>
        <main class="column">
          <section class="top-main">
            {html_list}
          </section>
        </main>

      </div>
    );
  }


}

/*

ReactDOM.render(
  <BlogList />,
  document.getElementById('root')
);
*/
